import { createApp } from 'vue'
// import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Google

// Import the functions you need from the SDKs you need
// require('firebase/firestore')
// require('firebase/app')

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyC4x5S6WbAng0aMicP1P6EeWA7f4g2U274',
  authDomain: 'playmaker-football.firebaseapp.com',
  projectId: 'playmaker-football',
  storageBucket: 'playmaker-football.appspot.com',
  messagingSenderId: '162563852651',
  appId: '1:162563852651:web:612efc46bcdb19455fa42e'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
createApp(App).use(store).use(router).mount('#app')
