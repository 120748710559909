<template>
    <div class="cover-container p-3 flex-row main-logo">
           <div class="flex-column justify-content-center">
                <img class="logo" src="assets/images/pm-logo-a.png">
            </div>
    </div>

    <div class="cover-container  h-100 p-3 mx-auto flex-row pitch">
        <div class="flex-column">
            <p class="lead blurb pitch">currently in preseason</p>
            <p class="tag-line">feel free to drop us a line at &#105;&#110;&#102;&#111;&#064;&#112;&#108;&#097;&#121;&#109;&#097;&#107;&#101;&#114;&#046;&#102;&#111;&#111;&#116;&#098;&#097;&#108;&#108;</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
